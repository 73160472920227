<template>
  <div class="group-info">
    <div class="cover">
      <a-avatar
        style="background-color: #5f87ad; font-size: 54px"
        shape="square"
        :size="130"
        :src="data.coverImg"
        >#</a-avatar
      >
    </div>
    <div class="text">
      <h2>{{ data.topicName }}</h2>
      <div class="right">
        <p>{{ data.intro }}</p>
        <div class="ctrl">
          <a-button type="primary" :loading="loading" @click="follow">
            {{ isFollow ? $t('bbs.unfollow', 1) : $t('bbs.follow', 1) }}
            <!-- "取消关注" : "关注话题" -->
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from "vue";
import { followTopic } from "@/api/bbs";
export default {
  name: "topicInfo",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props) {
    const state = reactive({
      loading: false,
      isFollow: false,
    });

    watch(
      () => props.data,
      () => {
        state.isFollow = props.data.isFollow === 1;
      },
      { immediate: true, deep: true }
    );

    const follow = () => {
      state.loading = true;
      followTopic({ id: props.data.topicId }).then((res) => {
        state.loading = false;
        state.isFollow = !state.isFollow;
      });
    };

    return {
      ...toRefs(state),
      follow,
    };
  },
};
</script>

<style lang="less" scoped>
.group-info {
  padding: 20px 24px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  .mixinFlex(space-between);
  .cover {
    width: 130px;
    height: 130px;
    border-radius: 4px;
    overflow: hidden;
  }
  .text {
    width: calc(100% - 150px);
    h2 {
      font-weight: normal;
      font-size: 20px;
      .mixinEllipsis(28px);
      color: #202020;
      margin-bottom: 10px;
    }
    .right {
      p {
        font-size: 14px;
        .mixinEllipsis(44px; 2);
        color: #666;
        margin-bottom: 16px;
      }
      .ctrl {
        .mixinFlex(flex-end);
        .ant-btn {
          margin-left: 12px;
        }
      }
    }
  }
}
</style>
