<template>
  <div class="top-list">
    <div v-for="item in data" :key="item.subjectId" @click="subjectJump(item)">
      <p>
        <tag text="置顶" />
        <!-- 置顶 -->
        <tag text="帖子" v-if="item.subjectType === 1" />
        <!-- 帖子 -->
        <tag text="问答" v-else-if="item.subjectType === 2" />
        <!-- 问答 -->
        <tag text="文章" v-else-if="item.subjectType === 3" />
        <!-- 文章 -->
        <tag text="精华" v-if="item.isCondensed === 1" />{{ item.title }}
        <!-- 精华 -->
      </p>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { getCurrentInstance } from "vue";
import tag from "./tag.vue";
export default {
  name: "topList",
  components: {
    tag,
  },
  props: {
    newWindow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: [],
    },
  },
  setup(props) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const subjectJump = (item) => {
      if (item.state < 8) {
        proxy.$message.error($t("bbs.join_circle_view_detail"));
        // 加入圈子才能查看主题详情！
        let url = `/bbs/group?groupId=${item.groupId}`;
        if (props.newWindow) {
          window.open(url + "&ddtab=true");
        } else {
          window.location.href = url;
        }
      } else {
        let url = `/bbs/detail?groupId=${item.groupId}&subjectId=${item.subjectId}`;
        if (props.newWindow) {
          window.open(url + "&ddtab=true");
        } else {
          window.location.href = url;
        }
      }
    };

    return {
      subjectJump,
    };
  },
};
</script>

<style lang="less" scoped>
.top-list {
  padding: 20px 24px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  div {
    margin-bottom: 6px;
    &:last-of-type {
      margin-bottom: 0;
    }
    p {
      margin: 0;
      color: #333;
      font-size: 16px;
      .mixinEllipsis(24px);
      cursor: pointer;
      .ant-tag {
        vertical-align: text-bottom;
      }
      &:hover {
        color: @color-theme;
      }
    }
  }
}
</style>
