<template>
  <div class="group-info" v-if="group.groupId">
    <div class="cover">
      <a-avatar shape="square" :size="130" :src="group.coverImg">
        {{ group.groupName }}
      </a-avatar>
    </div>
    <div class="text">
      <h2>{{ group.groupName }}</h2>
      <div class="right">
        <p>{{ group.intro }}</p>
        <div class="ctrl" v-if="group.state >= 8 || group.scope1 === 8">
          <!-- 1申请加入 2已拒绝 3邀请 8申请通过 9邀请通过 10管理员增加 -->
          <a-button
            v-if="group.masterUid === userInfo.userId"
            @click="editGroup"
            >{{ $t('bbs.edit') }}</a-button
          >
          <!-- 编辑 -->
          <a-button
            id="step_one"
            type="primary"
            v-if="
              group.masterUid === userInfo.userId ||
              group.adminUids.includes(userInfo.userId)
            "
            @click="$refs.modalInviteRef.openModal()"
            >{{ $t('bbs.invite_members') }}</a-button
          >
          <!-- 邀请成员 -->
          <a-button type="primary" v-if="group.state === 3" @click="agree"
            >{{ $t('bbs.agree_to_join') }}</a-button
          >
          <!-- 同意加入 -->
          <a-tooltip
            v-else-if="group.state === 1"
            :title="$t('bbs.has_application_wait')"
          >
          <!-- 已申请成功，请耐心等待审核！ -->
            <a-button type="primary" disabled>{{ $t('bbs.have_applied') }}</a-button>
            <!-- 已申请 -->
          </a-tooltip>
          <template v-else-if="group.state < 8">
            <a-button type="primary" v-if="group.isJoin === 3" @click="stopJoin"
              >{{ $t('bbs.apply_join') }}</a-button
            >
            <!-- 申请加入 -->
            <a-button type="primary" v-else @click="join">{{ $t('bbs.apply_join') }}</a-button>
            <!-- 申请加入 -->
          </template>
          <a-dropdown placement="bottomRight">
            <a-button style="padding: 0 8px"><EllipsisOutlined /></a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item
                  :key="1"
                  v-if="
                    group.masterUid === userInfo.userId ||
                    group.adminUids.includes(userInfo.userId)
                  "
                  @click="groupMemberJump"
                  >{{ $t('bbs.member_manage') }}</a-menu-item
                >
                <!-- 成员管理 -->
                <a-menu-item
                  :key="2"
                  v-if="group.masterUid === userInfo.userId"
                  @click="dissolve"
                  >{{ $t('bbs.disband_circle') }}</a-menu-item
                >
                <!-- 解散圈子 -->
                <a-menu-item
                  :key="3"
                  v-if="group.masterUid !== userInfo.userId && group.state >= 8"
                  @click="quit"
                  >{{ $t('bbs.quit_circle') }}</a-menu-item
                >
                <!-- 退出圈子 -->
                <a-menu-item :key="4" @click="$refs.modalShareRef.openModal()"
                  >{{ $t('XB_Share') }}</a-menu-item
                >
                <!-- 分享 -->
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
    </div>
  </div>
  <modalInvite ref="modalInviteRef" :group="group" v-if="group.groupId" />
  <modalShare ref="modalShareRef" :group="group" />
</template>

<script>
import { useI18n } from 'vue-i18n';
import { getCurrentInstance, createVNode } from "vue";
import { useRouter } from "vue-router";

import { joinGroup, agreeJoinGroup, quitGroup, dissolveGroup } from "@/api/bbs";

import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

import modalInvite from "./modalInvite.vue";
import modalShare from "./modalShare.vue";

export default {
  name: "groupInfo",
  components: {
    modalInvite,
    modalShare,
  },
  props: {
    newWindow: {
      type: Boolean,
      default: false,
    },
    userInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    group: {
      type: Object,
      default: () => {
        return { adminUids: "" };
      },
    },
  },
  emits: ["editGroup"],
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const router = useRouter();

    const editGroup = () => {
      emit("editGroup");
    };

    const groupMemberJump = () => {
      if (props.newWindow) {
        window.open(
          `/bbs/groupMember?ddtab=true&groupId=${props.group.groupId}`
        );
      } else {
        router.push({
          path: "/bbs/groupMember",
          query: {
            groupId: props.group.groupId,
          },
        });
      }
    };

    const stopJoin = () => {
      proxy.$message.warn($t('bbs.not_open_join'));
      // 该学习圈暂未开放加入！
    };

    const join = () => {
      Modal.confirm({
        title: () => $t('bbs.sure_join'),
        // 确定申请加入吗？
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          joinGroup({
            id: props.group.groupId,
          }).then((res) => {
            if (res.ret === 0) {
              props.group.state = 1;
              if (props.group.isJoin === 1) {
                proxy.$message.success($t('bbs.application_successful'));
                // 申请成功！
                location.reload();
              } else if (props.group.isJoin === 2) {
                proxy.$message.success($t('bbs.application_successful'));
                // 申请成功！
              }
            }
          });
        },
      });
    };

    const agree = () => {
      Modal.confirm({
        title: () => $t('bbs.sure_agree_join'),
        // 确定同意加入吗？
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          agreeJoinGroup({
            id: props.group.groupId,
          }).then((res) => {
            if (res.ret === 0) {
              proxy.$message.success($t('CM_Success'));
              // 操作成功！
              location.reload();
            }
          });
        },
      });
    };

    const quit = () => {
      Modal.confirm({
        title: () => $t('bbs.quit_circle_tip'),
        // 确定退出圈子吗？
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          quitGroup({
            id: props.group.groupId,
          }).then((res) => {
            if (res.ret === 0) {
              proxy.$message.success($t('CM_Success'));
              // 操作成功！
              location.reload();
            }
          });
        },
      });
    };

    const dissolve = () => {
      Modal.confirm({
        title: () => $t('bbs.disband_circle_tip'),
        // 确定解散圈子吗？
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          dissolveGroup({
            id: props.group.groupId,
          }).then((res) => {
            if (res.ret === 0) {
              proxy.$message.success($t('CM_Success'));
              // 操作成功！
              window.location.href = "/bbs";
            }
          });
        },
      });
    };

    return {
      editGroup,
      groupMemberJump,
      join,
      stopJoin,
      agree,
      quit,
      dissolve,
    };
  },
};
</script>

<style lang="less" scoped>
.group-info {
  height: 172px;
  padding: 20px 24px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  .mixinFlex(space-between);
  .cover {
    width: 130px;
    height: 130px;
    border-radius: 4px;
    overflow: hidden;
  }
  .text {
    width: calc(100% - 150px);
    h2 {
      font-weight: normal;
      font-size: 20px;
      .mixinEllipsis(28px);
      color: #202020;
      margin-bottom: 10px;
    }
    .right {
      p {
        font-size: 14px;
        .mixinEllipsis(44px; 2);
        color: #666;
        margin-bottom: 16px;
      }
      .ctrl {
        .mixinFlex(flex-end);
        .ant-btn {
          margin-left: 12px;
        }
      }
    }
  }
}
</style>
