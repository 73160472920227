<template>
  <div class="bbs">
    <div class="bbs-main">
      <div class="bbs-main-box">
        <topicInfo :data="topic" />
      </div>
      <div class="content">
        <typeTab @tabChange="tabChange" @search="tabSearch" @sort="tabSort" />
        <a-spin :spinning="loading">
          <div
            class="list"
            v-infinite-scroll="handleInfiniteOnLoad"
            :infinite-scroll-immediate-check="false"
            :infinite-scroll-disabled="scrollDisabled"
            infinite-scroll-watch-disabled="scrollDisabled"
            :infinite-scroll-distance="50"
          >
            <div
              class="list-item"
              v-for="item in subjectList"
              :key="item.subjectId"
            >
              <posts
                :newWindow="newWindow"
                :data="item"
                @previewVideo="(url) => $refs.previewVideoRef.openModal(url)"
              />
            </div>
            <a-empty
              v-if="!subjectList.length && !loading"
              style="padding: 50px 0"
            />
          </div>
        </a-spin>
        <div class="load-more" v-if="subjectList.length && !loading">
          {{ scrollDisabled ? $t('bbs.no_more') : $t('LB_Doc_Mobile_Load') }}
          <!-- "已经到底了" : "加载中..." -->
        </div>
      </div>
    </div>
    <div class="bbs-sidebar">
      <sidePost @post="post" />
      <div class="bbs-sidebar-box">
        <sideAnalysis :topicId="topicId" />
      </div>
    </div>
  </div>
  <modalPosts
    ref="modalPostsRef"
    :communityConfig="communityConfig"
    :topicId="topic.topicId"
    :topicStr="topic.topicName"
    @handleOk="postEnd(1)"
  />
  <modalQuestion
    ref="modalQuestionRef"
    :communityConfig="communityConfig"
    :topicId="topic.topicId"
    :topicStr="topic.topicName"
    @handleOk="postEnd(2)"
  />
  <modalArticle
    ref="modalArticleRef"
    :communityConfig="communityConfig"
    :topicId="topic.topicId"
    :topicStr="topic.topicName"
    @handleOk="postEnd(3)"
  />
  <previewVideo ref="previewVideoRef" />
</template>

<script>
import { reactive, toRefs, ref, computed, nextTick } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import { getCommunityConfig, getTopicDeatil, getSubjectList } from "@/api/bbs";

import tag from "./components/tag.vue";
import groupInfo from "./components/groupInfo.vue";
import topicInfo from "./components/topicInfo.vue";
import topList from "./components/topList.vue";
import typeTab from "./components/typeTab.vue";
import posts from "./components/posts.vue";
import sidePost from "./components/sidePost.vue";
import sideAnalysis from "./components/sideAnalysis.vue";
import modalPosts from "./components/modalPosts.vue";
import modalQuestion from "./components/modalQuestion.vue";
import modalArticle from "./components/modalArticle.vue";
import previewVideo from "./components/previewVideo.vue";

export default {
  components: {
    tag,
    groupInfo,
    topicInfo,
    topList,
    typeTab,
    posts,
    sidePost,
    sideAnalysis,
    modalPosts,
    modalQuestion,
    modalArticle,
    previewVideo,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      userInfo: computed(() => store.getters.userInfo),
      newWindow: computed(() => store.getters.companyInfo.useNewWindow == 1),
      loading: false,
      scrollDisabled: true,
      topicId: Number(route.query.topicId || 0),
      topic: {},
      communityConfig: {},
      subjectParams: {
        topicID: Number(route.query.topicId || 0),
        status: 1,
        pageSize: 10,
        page: 1,
        order: 1,
        subjectType: 0,
      },
      subjectList: [],
    });

    getCommunityConfig().then((res) => {
      state.communityConfig = res.data;
    });

    getTopicDeatil(state.topicId).then((res) => {
      state.topic = res.data;
    });

    const getSubject = () => {
      if (state.subjectParams.page === 1) {
        state.loading = true;
      }
      state.scrollDisabled = true;
      getSubjectList(state.subjectParams).then((res) => {
        state.loading = false;
        let list = res.data.list || [];
        state.subjectList.push(...list);
        if (state.subjectList.length < res.data.totals) {
          nextTick(() => {
            state.scrollDisabled = false;
          });
        } else {
          state.scrollDisabled = true;
        }
      });
    };
    getSubject();

    const tabChange = (type) => {
      state.subjectParams.subjectType = type;
      state.subjectParams.page = 1;
      state.subjectList = [];
      getSubject();
    };

    const tabSearch = (value) => {
      state.subjectParams.keyword = value;
      state.subjectParams.page = 1;
      state.subjectList = [];
      getSubject();
    };

    const tabSort = (value) => {
      state.subjectParams.order = value;
      state.subjectParams.page = 1;
      state.subjectList = [];
      getSubject();
    };

    const modalPostsRef = ref(null);
    const modalQuestionRef = ref(null);
    const modalArticleRef = ref(null);
    const post = (type) => {
      if (type === 1) {
        modalPostsRef.value.openModal();
      } else if (type === 2) {
        modalQuestionRef.value.openModal();
      } else if (type === 3) {
        modalArticleRef.value.openModal();
      }
    };

    const postEnd = (type) => {
      if (
        state.subjectParams.subjectType === type ||
        state.subjectParams.subjectType === 0
      ) {
        state.subjectParams.page = 1;
        state.subjectList = [];
        getSubject();
      }
    };

    const handleInfiniteOnLoad = () => {
      if (!state.scrollDisabled) {
        state.subjectParams.page++;
        getSubject();
      }
    };

    return {
      ...toRefs(state),
      tabChange,
      tabSearch,
      tabSort,
      modalPostsRef,
      modalQuestionRef,
      modalArticleRef,
      post,
      postEnd,
      handleInfiniteOnLoad,
    };
  },
};
</script>

<style lang="less" scoped>
.bbs {
  .mixinWrap();
  .mixinFlex(space-between);
  padding: 20px 0 80px;
  &-main {
    width: 906px;
    &-box {
      margin-bottom: 24px;
    }
  }
  &-sidebar {
    width: 270px;
    position: relative;
    &-box {
      margin-bottom: 24px;
    }
  }
  .banner {
    width: 906px;
    height: 256px;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 24px;
  }
  .content {
    width: 906px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    .list {
      padding: 0 30px 24px;
      min-height: 500px;
      &-item {
        padding: 30px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      }
    }
    .load-more {
      text-align: center;
      font-size: 14px;
      color: #999;
      line-height: 20px;
      padding: 0 0 24px 0;
    }
  }
}
</style>
