<template>
  <!-- 添加链接 -->
  <a-modal
    :title="$t('bbs.addpost.add_link')"
    :width="620"
    :maskClosable="false"
    :keyboard="false"
    :closable="false"
    v-model:visible="visible"
    @cancel="handleCancel"
  >
    <div>
      <p class="tips">
        {{ $t("bbs.addpost.link_tip") }}
        <!-- 请注意，若您分享的是一个外部链接，所有成员在第三方网站提交的信息将由该网站依据其隐私、保密和安全条款执行并负责。 -->
      </p>
      <a-form :model="formData" layout="vertical" ref="formRef">
        <div class="link-preview" v-if="isParsing">
          <div class="icon">
            <LinkOutlined style="color: #fff; font-size: 20px" />
          </div>
          <div class="info">
            <p>{{ formData.link }}</p>
            <span>
              {{
                formData.link.indexOf(currHost) === -1
                  ? $t("bbs.form", [$t("bbs.external_link")])
                  : $t("bbs.form", [$t("bbs.internal_link")])
              }}
              <!-- 来自外部链接 :  来自内部链接 -->
            </span>
          </div>
          <div class="close">
            <div class="close-btn" @click="delLink">
              <CloseOutlined />
            </div>
          </div>
        </div>
        <a-form-item
          v-else
          label=""
          name="link"
          :rules="[
            {
              required: true,
              message: $t('bbs.addpost.please_enter_link'),
              // 请输入链接
              trigger: 'change',
            },
            { validator: validatorUrl },
          ]"
        >
          <a-textarea
            :rows="3"
            v-model:value="formData.link"
            :placeholder="$t('bbs.addpost.link_start')"
          />
          <!-- 链接请以http://或者https://开头 -->
        </a-form-item>
      </a-form>
    </div>
    <template #footer>
      <div class="custom-footer">
        <div></div>
        <div class="btns">
          <a-button @click="handleCancel">{{ $t("CM_Cancel") }}</a-button>
          <!-- 取消 -->
          <a-button type="primary" v-if="isParsing" @click="handleOk">{{
            $t("CM_Confirm")
          }}</a-button>
          <!-- 确定 -->
          <a-button
            type="primary"
            v-else
            :loading="loading"
            @click="handleParsing"
            >{{ $t("bbs.addpost.parse") }}</a-button
          >
          <!-- 解析 -->
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, ref, createVNode } from "vue";

import { linkParsing } from "@/api/bbs";

import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

export default {
  name: "modalLink",
  emits: ["handleOk"],
  setup(_, { emit }) {
    const { t: $t } = useI18n();
    const state = reactive({
      visible: false,
      loading: false,
      isParsing: false,
      formData: {
        link: "",
      },
    });

    const validatorUrl = (rule, value, callback) => {
      if (value && !/(http|https):\/\/([\w.]+\/?)\S*/.test(value)) {
        return Promise.reject($t("bbs.addpost.url_format_incorrect"));
        // URL格式不正确
      }
      return Promise.resolve();
    };

    const openModal = (link) => {
      state.visible = true;
      state.formData.link = link;
      if (link) state.isParsing = true;
      formRef.value && formRef.value.resetFields();
    };

    const formRef = ref(null);
    const handleParsing = () => {
      formRef.value.validate().then(() => {
        linkParsing({
          keyword: state.formData.link,
        }).then((res) => {
          if (res.ret === 0) {
            state.isParsing = true;
          }
        });
      });
    };

    const delLink = () => {
      Modal.confirm({
        title: () => $t("CM_Tip"),
        // 提示
        icon: () => createVNode(ExclamationCircleOutlined),
        content: $t("bbs.addpost.delete_link_tip"),
        // 确认删除链接吗？
        onOk() {
          state.isParsing = false;
          state.formData.link = "";
        },
      });
    };

    const handleOk = () => {
      handleCancel();
    };

    const handleCancel = () => {
      emit("handleOk", state.formData.link);
      state.visible = false;
    };

    return {
      currHost: location.host,
      ...toRefs(state),
      validatorUrl,
      openModal,
      formRef,
      delLink,
      handleOk,
      handleParsing,
      handleCancel,
    };
  },
};
</script>

<style lang="less" scoped>
.tips {
  color: #666;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 12px;
}

.link-preview {
  padding: 10px 14px;
  background-color: #fafafa;
  border-radius: 4px;
  width: 100%;
  .mixinFlex(space-between);
  .icon {
    width: 40px;
    height: 40px;
    background-color: var(--theme);
    border-radius: 2px;
    .mixinFlex(center; center);
  }
  .info {
    width: calc(100% - 80px);
    .mixinFlex(center; flex-start; column);
    font-size: 12px;
    p {
      width: 100%;
      color: #333;
      .mixinEllipsis(20px);
      margin: 0;
      cursor: pointer;
    }
    span {
      color: #999;
      cursor: pointer;
    }
  }
  .close {
    width: 20px;
    .mixinFlex(center; center);
    &-btn {
      width: 20px;
      height: 20px;
      .mixinFlex(center; center);
      cursor: pointer;
    }
  }
}
</style>
