<template>
  <div class="type-tab">
    <ul class="type">
      <li
        v-for="item in types"
        :key="item.id"
        :class="{ active: activeType === item.id }"
        @click="onChange(item.id)"
      >
        {{ item.name }}
      </li>
    </ul>
    <div class="search">
      <a-input-search
        v-model:value="searchVal"
        :placeholder="$t('bbs.please_enter_title_author')"
        style="width: 200px"
        @search="onSearch"
      />
      <!-- 请输入标题/发布者 -->
      <a-select
        v-model:value="sort"
        style="width: 150px; text-align: right"
        :options="sortTypes"
        :bordered="false"
        dropdownClassName="type-tab-sort"
        @change="onSort"
        block
      />
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { reactive, toRefs } from "vue";
export default {
  name: "typeTab",
  props: {
    hasGroup: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["tabChange", "search", "sort"],
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const state = reactive({
      types: [
        { name: $t('CM_All'), id: 0 }, // 全部
        { name: $t('bbs.post'), id: 1 }, // 帖子
        { name: $t('bbs.ask'), id: 2 }, // 问答
        { name: $t('bbs.article'), id: 3 }, // 文章
      ],
      activeType: 0,
      searchVal: "",
      sortTypes: [
        { label: $t('bbs.latest_content'), value: 1 }, // 按最新
        { label: $t('bbs.recommended_content'), value: 2 }, // 按推荐
        { label: $t('bbs.hot_content'), value: 3 }, // 按热度
        { label: $t('bbs.latest_discussion'), value: 4 }, // 按讨论
      ],
      sort: 1,
    });

    if (props.hasGroup) {
      state.types.push({ name: $t('bbs.only_view_owner'), id: 4 });
      // 只看圈主
    }

    const onChange = (id) => {
      state.activeType = id;
      emit("tabChange", id);
    };

    const onSearch = (value) => {
      emit("search", value);
    };

    const onSort = (value) => {
      emit("sort", value);
    };

    return {
      ...toRefs(state),
      onChange,
      onSearch,
      onSort,
    };
  },
};
</script>

<style lang="less" scoped>
.type-tab {
  padding: 14px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  .mixinFlex(space-between; center);
  .type {
    margin-left: -5px;
    line-height: 32px;
    .mixinFlex(flex-start; center);
    li {
      padding: 0 5px;
      transition: color 0.3s;
      font-size: 16px;
      color: #333;
      margin-right: 30px;
      cursor: pointer;
      &.active,
      &:hover {
        color: @color-theme;
      }
    }
  }
}
</style>
<style lang="less">
.type-tab-sort {
  .ant-select-item {
    text-align: center;
  }
}
</style>
