<template>
  <a-modal
    title=""
    :footer="null"
    :width="488"
    centered
    wrapClassName="share-modal"
    :bodyStyle="{ padding: 0 }"
    v-model:visible="visible"
  >
    <div class="share">
      <div class="share-box" id="shareBox">
        <div class="share-title">
          <div class="name">
            "<OpenData type="userName" :openid="userInfo.realName" />"
          </div>
          <div class="p">{{ $t('bbs.invite_you_join_circle') }}</div>
          <!-- 邀请你加入学习圈 -->
        </div>
        <div class="share-group">
          <div class="cover">
            <a-avatar shape="square" :size="100" :src="group.coverImg">
              {{ group.groupName }}
            </a-avatar>
          </div>
          <div class="text">
            <h5>{{ group.groupName }}</h5>
            <div class="master">
              {{ $t('bbs.circle_owner') }}：<OpenData type="userName" :openid="group.masterName" />
              <!-- 圈主： -->
            </div>
            <p>{{ group.intro }}</p>
          </div>
        </div>
        <div class="share-qr">
          <div class="qrcode-box">
            <a-spin :spinning="spinning">
              <div id="qrcode" class="qrcode"></div>
            </a-spin>
          </div>
          <p class="p1">{{ $t('bbs.scan_code_enter_circle') }}</p>
          <!-- 扫码立即进入圈子 -->
          <p class="p2">
            {{ $t('bbs.link_browser') }}：<span>{{ href }}</span>
            <!-- 或浏览器输入链接： -->
          </p>
        </div>
      </div>
      <div class="share-btns">
        <a-button ghost class="btn" @click="copy">{{ $t('bbs.copy_link') }}</a-button>
        <!-- 复制链接 -->
        <a-button ghost class="btn" @click="save" :loading="loading"
          >{{ $t('bbs.save_card') }}</a-button
        >
        <!-- 保存邀请卡 -->
      </div>
    </div>
    <input id="hrefInput" type="text" v-model="href" />
  </a-modal>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { reactive, toRefs, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import html2canvas from "html2canvas";
import QRCode from "qrcodejs2";
import { currentHost } from "@/utils/request.js";
import OpenData from "@/components/OpenData.vue";
export default {
  name: "modalShare",
  components: {
    OpenData,
  },
  props: {
    group: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const state = reactive({
      userInfo: computed(() => store.getters.userInfo),
      platformConfig: computed(() => store.getters.platformConfig),
      visible: false,
      spinning: false,
      loading: false,
      host: "",
      href: "",
    });

    const getHost = () => {
      state.host =
        state.platformConfig.mobileDomain ||
        state.platformConfig.customMobileDomain;
      if (state.host) return;
      currentHost.split(".").map((item, index) => {
        if (index === 0) {
          state.host += item.replace("-pc", "") + "-m";
        } else {
          state.host += item;
        }
        if (index + 1 < currentHost.split(".").length) {
          state.host += ".";
        }
      });
    };

    let first = true;
    const openModal = () => {
      getHost();
      state.visible = true;
      let url = `${location.protocol}//${state.host}/view${location.pathname}${location.search}`;
      state.href = url;
      if (first) {
        state.spinning = true;
        first = false;
        setTimeout(() => {
          new QRCode("qrcode", {
            width: 160,
            height: 160,
            text: state.href,
            colorDark: "#000",
            colorLight: "#fff",
          });
          state.spinning = false;
        }, 600);
      }
    };

    const copy = () => {
      const hrefInput = document.getElementById("hrefInput");
      hrefInput.select();
      document.execCommand("copy");
      proxy.$message.success($t('bbs.copy_successfully'));
      // 复制成功
    };

    const save = () => {
      if (state.spinning) return false;
      state.loading = true;
      const domObj = document.getElementById("shareBox");
      html2canvas(domObj, {
        logging: false,
        allowTaint: true,
        useCORS: true,
        width: 488,
        height: 648,
        backgroundColor: "#1890ff",
      }).then(function (canvas) {
        if (state.platformConfig.platform == 26) {
          if (WWOpenData.initCanvas) {
            WWOpenData.initCanvas();
            WWOpenData.enableCanvasSharing();
          }
          const fn = async () => {
            const result = await new Promise((resolve, reject) => {
              WWOpenData.prefetch(
                {
                  items: [
                    {
                      type: "userName",
                      id: state.userInfo.realName,
                    },
                    {
                      type: "userName",
                      id: props.group.masterName,
                    },
                  ],
                },
                (err, data) => {
                  if (err) {
                    return reject(err);
                  }
                  resolve(data);
                }
              );
            });

            let imgUrl2 = canvas.toDataURL("image/png");
            let new_img = new Image(488, 648);
            new_img.src = imgUrl2;
            new_img.onload = function () {
              let canvas2 = document.createElement("canvas");
              canvas2.width = new_img.width;
              canvas2.height = new_img.height;

              let cxt = canvas2.getContext("2d");
              cxt.drawImage(new_img, 0, 0, 488, 648);
              cxt.fillStyle = "#2a8ff7";
              cxt.fillRect(0, 42, 488, 44);
              cxt.fillStyle = "#fff";
              cxt.font = 'bold 28px "Microsoft YaHei"';
              cxt.textAlign = "center";
              cxt.fillText(`"${result.items[0].data}"`, 488 / 2, 74);
              cxt.fillStyle = "#666";
              cxt.font = '14px "Microsoft YaHei"';
              cxt.fillText(result.items[1].data, 210, 204);

              let imgUrl = canvas2.toDataURL("image/png");
              let tempLink = document.createElement("a");
              tempLink.style.display = "none";
              tempLink.href = imgUrl;
              tempLink.setAttribute("download", props.group.groupName);
              if (typeof tempLink.download === "undefined") {
                tempLink.setAttribute("target", "_blank");
              }
              document.body.appendChild(tempLink);
              tempLink.click();
              document.body.removeChild(tempLink);
              window.URL.revokeObjectURL(imgUrl);
              state.loading = false;
            };
          };
          fn();
        } else {
          let imgUrl = canvas.toDataURL("image/png");
          let tempLink = document.createElement("a");
          tempLink.style.display = "none";
          tempLink.href = imgUrl;
          tempLink.setAttribute("download", props.group.groupName);
          if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
          }
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(imgUrl);
          state.loading = false;
        }
      });
    };

    return {
      ...toRefs(state),
      openModal,
      copy,
      save,
    };
  },
};
</script>

<style lang="less" scoped>
.share {
  position: relative;
  &-box {
    width: 488px;
    height: 648px;
    border-radius: 8px;
    background: url(~@/assets/image/bbs/share_bg.png) 100% 100%;
    padding-top: 43px;
    position: relative;
  }
  #share-canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &-title {
    font-weight: 500;
    text-align: center;
    color: #fff;
    .name {
      font-size: 28px;
      line-height: 44px;
    }
    .p {
      font-size: 22px;
      line-height: 34px;
    }
  }
  &-group {
    padding: 35px 38px 25px;
    .mixinFlex(space-between);
    .cover {
      width: 100px;
      height: 100px;
      border-radius: 8px;
      overflow: hidden;
    }
    .text {
      width: calc(100% - 116px);
      h5 {
        font-size: 16px;
        font-weight: normal;
        color: #202020;
        .mixinEllipsis(23px);
        margin-bottom: 10px;
      }
      .master {
        font-size: 14px;
        color: #666666;
        line-height: 20px;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        color: #666666;
        .mixinEllipsis(40px; 2);
        margin-bottom: 0;
      }
    }
  }
  &-qr {
    padding: 26px 0 31px;
    text-align: center;
    .qrcode-box {
      display: inline-block;
      width: 160px;
      height: 160px;
      margin-bottom: 13px;
      overflow: hidden;
      .qrcode {
        width: 160px;
        height: 160px;
      }
    }
    .p1 {
      font-size: 12px;
      line-height: 17px;
      color: #666;
      margin-bottom: 10px;
    }
    .p2 {
      line-height: 17px;
      font-size: 12px;
      color: #999;
      margin-bottom: 0;
      padding: 0 18px;
      span {
        color: #333;
      }
    }
  }
  &-btns {
    position: absolute;
    bottom: 3px;
    left: 0;
    right: 0;
    .mixinFlex(space-between);
    padding: 21px 62px;
    .btn {
      width: 163px;
      height: 40px;
      .mixinFlex(center; center);
      font-size: 16px;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
#hrefInput {
  position: absolute;
  bottom: 10000px;
  right: 10000px;
}
</style>
<style lang="less">
.share-modal {
  .ant-modal {
    padding-bottom: 0;
    border-radius: 8px;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-close {
    top: 5px;
    right: 12px;
  }
  .ant-modal-close-x {
    font-size: 20px;
    color: #fff;
  }
}
</style>
