<template>
  <div class="side-post" id="step_two">
    <div class="side-post-item" @click="post(1)">
      <div class="icon">
        <img src="@/assets/image/bbs/posts_1.png" :alt="$t('bbs.add_post')" />
      </div>
      <div class="text">{{ $t("bbs.add_post") }}</div>
      <!-- 发帖子 -->
    </div>
    <div class="side-post-item" @click="post(2)">
      <div class="icon">
        <img src="@/assets/image/bbs/posts_2.png" :alt="$t('bbs.add_ask')" />
      </div>
      <div class="text">{{ $t("bbs.add_ask") }}</div>
      <!-- 提问题 -->
    </div>
    <div class="side-post-item" @click="post(3)">
      <div class="icon">
        <img
          src="@/assets/image/bbs/posts_3.png"
          :alt="$t('bbs.add_article')"
        />
      </div>
      <div class="text">{{ $t("bbs.add_article") }}</div>
      <!-- 写文章 -->
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { getCurrentInstance } from "vue";
export default {
  name: "sidePost",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["post"],
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();

    const post = (type) => {
      document.body.click();
      if (props.disabled) {
        proxy.$message.warn($t("bbs.not_post_tip"));
        // 内容发布功能已被关闭，如需使用，请与管理员联系！
        return false;
      }
      emit("post", type);
    };

    return {
      post,
    };
  },
};
</script>

<style lang="less" scoped>
.side-post {
  padding: 20px 33px;
  position: sticky;
  top: 10px;
  z-index: 1;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  margin-bottom: 24px;
  .mixinFlex(space-between);
  &-item {
    cursor: pointer;
    .icon {
      width: 40px;
      height: 40px;
      .mixinImgWrap(40px; 40px);
    }
    .text {
      margin-top: 8px;
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.65);
      text-align: center;
    }
  }
}
</style>
